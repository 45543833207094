import { PersonAddOutlined, PersonRemoveOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setColleagues } from "../state/auth";
import FlexBetween from "./FlexBetween";
import { getApiUrl } from "../api/axios";

const Colleague = ({ colleagueId, name, subtitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_id = useSelector((state) => state.auth.user_id);
  const colleagues = useSelector((state) => state.auth.colleagues);
  const API_URL = getApiUrl();
  const colleagues_url = API_URL.concat(`/user/colleagues/${user_id}/${colleagueId}`);

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  const isColleague = colleagues.find(
    (colleague) => colleague.id === colleagueId
  );

  const patchColleague = async () => {
    const response = await fetch(colleagues_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    dispatch(setColleagues(data.data));
  };

  return (
    <FlexBetween>
      <FlexBetween gap="1rem">
        <Box
          onClick={() => {
            navigate(`/profile/${colleagueId}`);
            navigate(0);
          }}
        >
          <Typography
            color={main}
            variant="h5"
            fontWeight="500"
            sx={{
              "&:hover": {
                color: palette.primary.light,
                cursor: "pointer",
              },
            }}
          >
            {name}
          </Typography>
          <Typography color={medium} fontSize="0.75rem">
            {subtitle}
          </Typography>
        </Box>
      </FlexBetween>
      <IconButton
        onClick={() => patchColleague()}
        sx={{ backgroundColor: primaryLight, p: "0.6rem" }}
      >
        {isColleague ? (
          <PersonRemoveOutlined sx={{ color: primaryDark }} />
        ) : (
          <PersonAddOutlined sx={{ color: primaryDark }} />
        )}
      </IconButton>
    </FlexBetween>
  );
};

export default Colleague;
