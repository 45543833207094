import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import FlexBetween from "../../FlexBetween";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import 'moment/locale/en-gb';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';


const CustomDateTimePicker = ({
    dateTimeLabel,
    showDateTimePicker,
    selectedDateTime,
    onDateTimeChange,
    dateTimeError
  }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    if (!showDateTimePicker) return null;
  
    return (
      <FlexBetween gap="3rem" mt={2} mb={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label={dateTimeLabel}
            format="dd-MM-yyyy HH:mm:ss"
            value={selectedDateTime}
            onChange={onDateTimeChange}
            slotProps={{
              textField: { variant: "outlined" },
              actionBar: {
                actions: ["cancel", "accept"],
                sx: {
                  "& button": {
                    color: colors.grey[100], // Set the text color to white
                  },
                },
              },
            }}
            showTime={false}
            error={dateTimeError}
          />
        </LocalizationProvider>
      </FlexBetween>
    );
  };

export default CustomDateTimePicker;
