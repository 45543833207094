import {
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ArrowCircleRight,
} from "@mui/icons-material";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import RecyclingOutlinedIcon from "@mui/icons-material/RecyclingOutlined";
import { useTranslation } from "react-i18next";
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import { AttachFileOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Snackbar,
} from "@mui/material";
import FlexBetween from "../../components/FlexBetween";
import WidgetWrapper from "../../components/WidgetWrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEvent } from "../../state/reactor";
import { createAxiosPrivate } from "../../api/axios";
import { tokens } from "../../theme";
import {
  formatISO8601Timestamp,
} from "../../utils/formatting";
import { toPascalCase } from "../../utils/formatting";
import { useNavigate } from "react-router-dom";
import { validateComment } from "../../utils/validation";

const EventWidget = ({
  reactorId,
  eventId,
  eventUserId,
  eventUserName,
  name,
  type_name,
  type_id,
  content,
  description,
  location,
  created_at,
  likes,
  comments,
  files,
}) => {
  const { t } = useTranslation();
  const axiosPrivate = createAxiosPrivate();
  const [isComments, setIsComments] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUserId = useSelector((state) => state.auth.user_id);
  const isLiked = Boolean(likes[loggedInUserId]);
  const likeCount = Object.keys(likes).length;
  const [commentText, setCommentText] = useState(""); // New state for comment text
  const [showCommentBox, setShowCommentBox] = useState(false); // New state to show/hide comment box
  const [errors, setErrors] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // const [eventPicturePath, setEventPicturePath] = useState("");
  // TODO: Add and test proper event files pathing
  // if (type_name === "feed"){
  //   setEventPicturePath("foom_background.png")
  // }

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let like_endpoint = `/events/${reactorId}/${eventId}/like`;
  let comment_endpoint = `/events/${reactorId}/${eventId}/comment`;

  const eventTypeStyle = {
    feed: {
      color: colors.blueAccent[800],
      icon: RestoreFromTrashOutlinedIcon,
    },
    harvest: {
      color: colors.redAccent[800],
      icon: RecyclingOutlinedIcon,
    },
    generic: {
      color: colors.greenAccent[800],
      icon: LiveHelpOutlinedIcon,
    },
  };
  const eventTypeStyleObject = eventTypeStyle[type_name] || eventTypeStyle['generic'];
  const EventIcon = eventTypeStyleObject.icon;

  /* Function to handle like submission, if user like exits - removes like */
  const submitLike = async () => {
    try {
      const response = await axiosPrivate.post(like_endpoint, {});
      dispatch(setEvent(response.data.data));
    } catch (error) {
      const errorMessage = "Error submitting like";
      setErrors(errorMessage);
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
      return;
    }
  };

  /* Function to handle comment submission */
  const submitComment = async () => {
    const validationErrors = validateComment(commentText, t);
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      setSnackbarMessage(validationErrors.join(", "));
      setSnackbarOpen(true);
      return;
    }
    try {
      const response = await axiosPrivate.post(comment_endpoint, {
        comment: commentText,
      });
      // Update the event data with the new comment
      dispatch(setEvent(response.data.data));
      setCommentText("");
      setShowCommentBox(false);
    } catch (error) {
      const errorMessage = "Error submitting comment";
      setErrors(errorMessage);
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
      return;
    }
  };

  const handleEventClick = () => {
    navigate("/event/" + eventId, { state: { eventId } });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Paper
      elevation={4}
      style={{
        margin: "1rem",
        padding: "1rem",
        backgroundColor: eventTypeStyleObject.color,
      }}
    >
      <WidgetWrapper>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Tooltip title={t(`eventTypes.${type_name}`)} placement="top">
            <EventIcon style={{ fontSize: "2rem" }} />
          </Tooltip>
        </Box>
        <FlexBetween gap="1rem">
          <Box>
            <Typography
              color={colors.grey[100]}
              variant="h5"
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: colors.grey[100],
                  cursor: "pointer",
                },
              }}
            >
              {formatISO8601Timestamp(created_at)} · {name}
            </Typography>
            <Typography color={colors.grey[100]} fontSize="0.75rem">
              {location}
            </Typography>
          </Box>
          <Typography color={colors.grey[300]} variant="h3" sx={{}}>
            {eventUserName}
          </Typography>
        </FlexBetween>
        <Typography color={colors.grey[100]} sx={{ mt: "1rem" }}>
          {description}
        </Typography>
        {content?.weight != null && (
          <Typography color={colors.grey[100]} sx={{ mt: "1rem" }}>
            Weight: {content.weight}kg
          </Typography>
        )}
        {content?.recirculated != null && (
          <Typography color={colors.grey[100]} sx={{ mt: "1rem" }}>
            Recirculated: {content.recirculated}kg
          </Typography>
        )}
        <FlexBetween mt="0.25rem">
          <FlexBetween gap="1rem">
            {/* Like Icon with Like Count */}
            <FlexBetween gap="0.3rem">
              <IconButton onClick={submitLike}>
                {isLiked ? (
                  <FavoriteOutlined sx={{ color: colors.grey[100] }} />
                ) : (
                  <FavoriteBorderOutlined />
                )}
              </IconButton>
              <Typography>{likeCount}</Typography>
            </FlexBetween>

            {/* Comment Icon with comment Count */}
            <FlexBetween gap="0.3rem">
              <IconButton onClick={() => setIsComments(!isComments)}>
                <ChatBubbleOutlineOutlined />
              </IconButton>
              <Typography>{comments.length}</Typography>
            </FlexBetween>

            {/* Camera Icon with File Count */}
            {files.length > 0 && (
              <FlexBetween gap="0.3rem">
                <IconButton onClick={handleEventClick}>
                  <AttachFileOutlined />
                </IconButton>
                <Typography>{files.length}</Typography>
              </FlexBetween>
            )}
          </FlexBetween>

          <IconButton onClick={handleEventClick}>
            <ArrowCircleRight />
          </IconButton>
        </FlexBetween>
        <FlexBetween gap="1rem">
          <Button
            variant="contained"
            sx={{ bgcolor: colors.primary[400], color: colors.redAccent[500] }}
            onClick={() => setShowCommentBox(!showCommentBox)}
          >
            {showCommentBox ? t("actions.cancel") : t("actions.addComment")}
          </Button>
        </FlexBetween>

        {/* Comment Input and Submit Button */}
        {showCommentBox && (
          <Box mt="1rem">
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t("placeholders.enterYourComment")}
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={submitComment}
              disabled={!commentText}
            >
              Submit Comment
            </Button>
          </Box>
        )}
        {isComments && (
          <Box mt="0.5rem">
            {comments.map((comment, i) => (
              <Box key={`${name}-${i}`}>
                <Divider />
                <Typography
                  sx={{ color: colors.grey[100], m: "0.5rem 0", pl: "1rem" }}
                >
                  {formatISO8601Timestamp(comment.created_at)} ·{" "}
                  {toPascalCase(comment.author_username)}: {comment.comment}
                </Typography>
              </Box>
            ))}
            <Divider />
          </Box>
        )}
      </WidgetWrapper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Paper>
  );
};

export default EventWidget;
