import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MenuItem, Select, FormControl, Box } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { createAxiosPrivate } from '../api/axios';
import { setLanguage } from "../state/auth";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
  const axiosPrivate = createAxiosPrivate();
  const dispatch = useDispatch();

  const handleChange = async (event) => {
    const newLanguage = event.target.value;

    setSelectedLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
    dispatch(setLanguage({ language: newLanguage }));
    try {
        const response = await axiosPrivate.post(`/user/settings/language`, {
          language: newLanguage,
        }, {});
  
        console.log(response.data.message);
        
      } catch (error) {
        console.error("Failed to update language preference", error);
      }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <Select
          value={selectedLanguage}
          onChange={handleChange}
          displayEmpty
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="de">Deutsch</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageSelector;