import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import ColleagueListWidget from "../widgets/ColleagueListWidget";
import { useTranslation } from 'react-i18next';

const Colleagues = () => {
  const { t } = useTranslation();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const  id = useSelector((state) => state.auth.user_id);
  const reactorId = useSelector((state) => state.auth.reactorId);

  return (
      <Box>
        <Box
          width="100%"
          padding="2rem 6%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="0.5rem"
          justifyContent="space-between"
        >
          <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
            {/* <UserWidget userId={id} /> */}
          </Box>
          <Box
            flexBasis={isNonMobileScreens ? "42%" : undefined}
            mt={isNonMobileScreens ? undefined : "2rem"}
          >
            <ColleagueListWidget key={reactorId} userId={id}/>
          </Box>
          {isNonMobileScreens && (
            <Box flexBasis="26%">
              <Box m="2rem 0" />
              {/* <ColleagueListWidget userId={id} /> */}
            </Box>
          )}
        </Box>
      </Box>
    );
  };

export default Colleagues;