import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "../../components/FlexBetween";
import WidgetWrapper from "../../components/WidgetWrapper";
import { useState, useEffect } from "react";
import { createAxiosPrivate } from "../../api/axios";
import { tokens } from "../../theme";
import { formatISO8601Timestamp} from "../../utils/formatting";
import { toPascalCase } from "../../utils/formatting";
import { useNavigate } from "react-router-dom";
import EventFile from "../../components/events/EventFile";
import { useTranslation } from 'react-i18next';

const DetailedEventWidget = ({ reactorId, eventId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [eventData, setEventData] = useState(null);
  const endpoint = `/events/${reactorId}/${eventId}`;
  const axiosPrivate = createAxiosPrivate();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axiosPrivate.get(endpoint);
        setEventData(response.data.data.event);
      } catch (err) {
        console.error(t('errors.fetchingEventData'), err);
      }
    };

    fetchEvent();
  }, [t]);

  if (!eventData) {
    return <div>{t('loading')}</div>;
  }

  const {
    name,
    created_at,
    location,
    description,
    content,
    comments = {},
    likes = {},
    files = [],
  } = eventData;
  const likeCount = Object.keys(likes).length;

  return (
    <Paper elevation={4} style={{ margin: "1rem", padding: "1rem" }}>
      <Button
        sx={{ color: colors.grey[100] }}
        onClick={() => navigate(-1)}
        style={{ marginBottom: '1rem' }}
      >
        Back
      </Button>
      <WidgetWrapper>
        {/* Event details */}
        <FlexBetween gap="1rem">
          <Box>
          <Typography sx={{ color: colors.grey[100] }}>
                {(formatISO8601Timestamp(created_at))}
            </Typography>
            <Typography
              color={colors.grey[100]}
              variant="h5"
              fontWeight="500"
              fontSize="1.5rem"
              sx={{
                "&:hover": {
                  color: colors.grey[100],
                  cursor: "pointer",
                },
              }}
            >
              {name}
            </Typography>
            <Typography color={colors.grey[100]} fontSize="0.75rem">
              {location}
            </Typography>
            <Typography color={colors.grey[100]} fontSize="1.2rem">
              {description}
            </Typography>
          </Box>
        </FlexBetween>

        {/* File Section */}
        {files && files.length > 0 && (
          <Box>
            {files.map((file, index) => (
              <EventFile
                key={index}
                src={file.src}
                alt={`Event File ${index + 1}`}
                filename={file.filename}
              />
            ))}
          </Box>
        )}

        {/* Comments section */}
        {comments && comments.length > 0 && (
          <Box mt="0.5rem">
            {comments.map((comment, i) => (
              <Box key={`${eventId}-${i}`}>
                <Divider />
                <Typography
                  sx={{ color: colors.grey[100], m: "0.5rem 0", pl: "1rem" }}
                >
                  {formatISO8601Timestamp(comment.created_at)} ·{" "}
                  {toPascalCase(comment.author_username)}: {comment.comment}
                </Typography>
              </Box>
            ))}
            <Divider />
          </Box>
        )}
      </WidgetWrapper>
    </Paper>
  );
};

export default DetailedEventWidget;
